import React, { useContext, useState, useEffect } from 'react'
import { UserContext } from '../../../contexts/UserContext'
import { NavigationContext } from '../../../contexts/NavigationContext';
import { Redirect } from 'react-router-dom';
import Locales from '../../../assets/translation/locales';
import Paper from '../../../components/paper/Paper';
import TextField from '../../../components/textField/TextField';
import Divider from '../../../components/divider/Divider';
import Button from '../../../components/button/Button';
import Form from '../../../components/form/Form';


const SignUp = () => {
    const { user, signUpUser, setUser } = useContext(UserContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const { setNavigation } = useContext(NavigationContext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setNavigation('Inscription', 'home'), []);

    const signUp = () => {
        if (email && firstName && lastName && password) {
            signUpUser(email, password, firstName, lastName);
            setEmail("");
            setPassword("");
            setFirstName("");
            setLastName("");
        } else {
            setUser({ ...user, status: "every-state-must-be-filled" })
        }
    }

    return user.isConnected ? (<Redirect to="/" />) : (
        <div>
            <h2>{Locales(user.data.langage, "to-singup")}</h2>
            <Paper padding="medium" align="center">
                <Form onSubmit={() => signUp()}>
                    <TextField label={Locales(user.data.langage, "first-name")} setValue={setFirstName} value={firstName} type="text" />
                    <Divider />
                    <TextField label={Locales(user.data.langage, "last-name")} setValue={setLastName} value={lastName} type="text" />
                    <Divider />
                    <TextField label={Locales(user.data.langage, "email")} setValue={setEmail} value={email} type="email" />
                    <Divider />
                    <TextField label={Locales(user.data.langage, "password")} setValue={setPassword} value={password} type="password" />
                    <Divider />
                    {user.status ? <div><Divider /><p className="error-message">{Locales(user.data.langage, user.status)}</p></div> : null}
                    <Button value={Locales(user.data.langage, "connect")} action={() => signUp()} />
                </Form>
                <Divider color="#D8D8D8" height="2px" margin="20px" />
                <Button to="/signin" value={Locales(user.data.langage, "already-account")} />
            </Paper>
        </div>
    )
}

export default SignUp
