import React, { useContext, useEffect } from 'react';
import { BoxContext } from '../../../contexts/BoxContext';
import { NavigationContext } from '../../../contexts/NavigationContext';
import { LoadingContainer } from '../../../components/overlay/desktop/Loading';
import { IdeaContext } from '../../../contexts/IdeaContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import Card from '../../../components/card/Card';
import l from '../../../assets/translation/locales';

// const IdeaHome = (props) => {
//     const { organization, updateOrganization } = useContext(OrganizationContext)
//     const { box, updateBox } = useContext(BoxContext);
//     const { ideas, updateIdeas } = useContext(IdeaContext);
//     const { organizationURI, boxURI, categoryURI } = props.match.params;

//     useEffect(() => {
//         updateOrganization('uri', organizationURI)
//         updateBox('uri', boxURI)
//         updateIdeas()
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [])

//     const { setNavigation } = useContext(NavigationContext);
//     // On écoute sur updated, si il est false, on affiche 'chargement' dans la navbar, sinon on affiche le titre de la box
//     useEffect(() => {
//         if (!box.updated && box.id) {
//             setNavigation(box.data.categories.filter(c => c.uri === categoryURI)[0].title, 'back', '/' + organizationURI + '/' + boxURI, box.data.title)
//             // if (categoryURI === 'all' && box.data.categories.length === 1) {
//             //     setNavigation('Toutes les idées', '/' + organizationURI + '/' + boxURI, box.data.title)
//             // } else {
//             //     setNavigation(box.data.categories.filter(c => c.uri === categoryURI)[0].title, 'back', '/' + organizationURI + '/' + boxURI, box.data.title)
//             // }
//         } else {
//             setNavigation('Chargement...', 'back')
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [box]);

//     return ideas.data.length > 0 ? (
//         <div>
//             <IdeaList organizationURI={organizationURI} boxURI={boxURI} categoryURI={categoryURI} ideas={ideas.data} />
//         </div>
//     ) : <LoadingContainer />;
// }

const IdeaHome = (props) => {
    // Contexts imports
    const { updateOrganization } = useContext(OrganizationContext)
    const { box, updateBox } = useContext(BoxContext);
    const { ideas, updateIdeas } = useContext(IdeaContext);
    const { setNavigation } = useContext(NavigationContext);
    const { organizationURI, boxURI, categoryURI } = props.match.params;

    // Organization, boxes and navigation status updates
    useEffect(() => {
        updateOrganization('uri', organizationURI)
        updateBox('uri', boxURI)
        updateIdeas()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!box.updated && box.id) {
            if (categoryURI === "all") {
                setNavigation(l("all"), 'back', `/${organizationURI}/${boxURI}`, box.data.title)
            } else {
                setNavigation(box.data.categories.filter(c => c.uri === categoryURI)[0].title, 'back', `/${organizationURI}/${boxURI}`, box.data.title)
            }
        } else {
            setNavigation('Chargement...', 'back')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [box]);

    return ideas.data.length > 0 ? (
        <div>
            <h2>{l("all-description")}</h2>
            {ideas.data.map(idea => <Card to={`/${organizationURI}/${boxURI}/${categoryURI}/${idea.data.uri}`} key={idea.data.uri} title={idea.data.title} description={idea.data.title} />)}
        </div>
    ) : <LoadingContainer />;
}

export default IdeaHome;