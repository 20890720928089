import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

var config = {
    apiKey: "AIzaSyBnROVfmDyY8H3l22P867reuh7ZjIPLFyk",
    authDomain: "brusketa-dev.firebaseapp.com",
    databaseURL: "https://brusketa-dev.firebaseio.com",
    projectId: "brusketa-dev",
    storageBucket: "brusketa-dev.appspot.com",
    messagingSenderId: "155010431619",
    appId: "1:155010431619:web:2a98e75b642a0a6fdcb9b6",
    measurementId: "G-BKF4CYRJ1Y"
  };

firebase.initializeApp(config);
// firebase.firestore().settings({timestampsInSnapshots:true})

export default firebase;
