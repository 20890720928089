import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../../contexts/UserContext';
import { BoxContext } from '../../../contexts/BoxContext';
import Paper from '../../../components/paper/Paper';
import Form from '../../../components/form/Form';
import TextField from '../../../components/textField/TextField';
import Divider from '../../../components/divider/Divider';
import Button from '../../../components/button/Button';
import Select from '../../../components/select/Select';
import { NavigationContext } from '../../../contexts/NavigationContext';
import l from '../../../assets/translation/locales';

const BoxCreate = () => {
    const { addBox } = useContext(BoxContext);
    const { user } = useContext(UserContext);
    const { setNavigation } = useContext(NavigationContext);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [organizationId, setOrganizationId] = useState('');

    useEffect(() => {
        setNavigation(l("new-box"), 'home');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const createBox = () => {
        addBox(name, description, organizationId);
        setName('');
        setDescription('');
    }

    return (
        <div>
            <h2>{l("to-create-box")}</h2>
            <Paper padding="medium" align="center">
                <Form onSubmit={() => createBox()}>
                    <TextField label={l("name")} setValue={setName} value={name} type="text" />
                    <Divider />
                    <TextField label={l("description")} setValue={setDescription} value={description} type="text" />
                    <Divider />
                    {user.data.organizations
                        ? <Select name="organization" values={user.data.organizations} defaultValue={user.data.organizations[0].id} onChange={setOrganizationId} idKey="id" valueKey="title" />
                        : <p className="error-message">Vous devez créer une organisation pour créer une boîte</p>}
                    {/* {user.status ? <div><Divider /><p className="error-message">{Locales(user.data.langage, user.status)}</p></div> : null} */}
                    <Divider />
                    <Button value={l("create")} action={() => createBox()} />
                </Form>
            </Paper>
        </div>
    )
}

export default BoxCreate
