import React, { createContext, useState, useContext, useEffect } from 'react';
import firebase from 'firebase/app'
import urlSlug from 'url-slug'
import { OrganizationContext } from './OrganizationContext';

export const BoxContext = createContext();

const BoxContextProvider = (props) => {
    const organizations = firebase.firestore().collection('organization');

    const [box, setBox] = useState({});
    const [boxes, setBoxes] = useState({ organization: null, data: [] });
    const { organization } = useContext(OrganizationContext);

    useEffect(() => {
        if (!organization.update && box.update) {
            organizations.doc(organization.id).collection('box').where(box.update.key, '==', box.update.value).get().then(snapshotBox => {
                if (snapshotBox.empty) {
                    console.log("box pas trouvée !");
                } else {
                    snapshotBox.forEach(docBox => {
                        let boxInter = {
                            id: docBox.id,
                            data: docBox.data()
                        };
                        setBox(boxInter);
                        console.log('BoxContext', 'box ' + docBox.id + ' updated');
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization, box]);

    const updateBox = (key, value) => {
        if (!((box.data) && (box.data[key]) && (box.data[key] === value))) {
            setBox({
                ...box,
                update: {
                    key,
                    value
                }
            });
        }
    }

    useEffect(() => {
        if (!organization.update && organization.id && boxes.organization !== organization.id && boxes.update) {
            organizations.doc(organization.id).collection('box').get().then(snapshot => {
                var liste = [];
                snapshot.forEach(doc => {
                    liste.push({
                        id: doc.id,
                        data: doc.data()
                    });
                });
                setBoxes({
                    organization: organization.id,
                    data: liste
                });
                console.log('BoxContext', liste.length + ' boxes updated');
            }, err => {
                console.log(`Encountered error: ${err}`);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization, boxes]);

    const updateBoxes = () => {
        if (boxes.data.length === 0) {
            setBoxes({
                organization: null,
                update: true,
                data: []
            });
        }
    }

    const addBox = (name, description, organizationId) => {
        const unsub = organizations.doc(organizationId).collection('box').add({
            title: name,
            content: description,
            uri: urlSlug(name)
        }).then(() => {
        }).catch((error) => {
            console.log(error.code, error.message);
        });
        return () => unsub();
    }

    const getBox = (boxURI, callback) => {
        if (organization.id) {
            organizations.doc(organization.id).collection('box').where('uri', '==', boxURI).get().then(snapshotBox => {
                if (snapshotBox.empty) {
                    console.log("box pas trouvée !");
                } else {
                    snapshotBox.forEach(docBox => {
                        let boxInter = {
                            id: docBox.id,
                            title: docBox.data().title,
                            content: docBox.data().content,
                            uri: docBox.data().uri
                        };
                        boxInter.category = []
                        organizations.doc(organization.id).collection('box').doc(docBox.id).collection('category').get().then(snapshotCategory => {
                            snapshotCategory.forEach(docCategory => {
                                boxInter.category.push(docCategory.data());
                            });
                            setBox(boxInter);
                            callback(true);
                        });
                    });
                }
            });
        }
    }

    const getBoxes = () => {
        if (organization.id) {
            const unsub = organizations.doc(organization.id).collection('box').onSnapshot(snapshot => {
                var liste = [];
                snapshot.forEach(doc => {
                    liste.push({
                        id: doc.id,
                        title: doc.data().title,
                        description: doc.data().content,
                        private: doc.data().private,
                        uri: doc.data().uri
                    })
                });
                setBoxes(liste);
            }, err => {
                console.log(`Encountered error: ${err}`);
            });
            return () => unsub();
        } else {
            console.log('Organization not defined')
            return null;
        }
    }

    return (
        <BoxContext.Provider value={{ box, boxes, organization, getBoxes, getBox, addBox, updateBox, updateBoxes }}>
            {props.children}
        </BoxContext.Provider>
    );
}

export default BoxContextProvider;