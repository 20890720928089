import React, { useContext, useEffect } from 'react'
import './Home.css'
import { NavigationContext } from '../../../contexts/NavigationContext'
import { OrganizationContext } from '../../../contexts/OrganizationContext'
import Card from '../../../components/card/Card'
import { BoxContext } from '../../../contexts/BoxContext'
import Loading from '../../../components/overlay/desktop/Loading'
import l from '../../../assets/translation/locales'

const Home = () => {
    // Contexts imports
    const { setNavigation, setPath } = useContext(NavigationContext);
    const { organization, updateOrganization } = useContext(OrganizationContext);
    const { boxes, updateBoxes } = useContext(BoxContext);

    // Organization, boxes and navigation status updates
    useEffect(() => {
        updateOrganization('uri', 'fayeto');
        updateBoxes();
        setNavigation(l("welcome"), 'home');
        setPath();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <h2>{l("discover-suggestion-boxes-of-the-moment") + " :"}</h2>
            {boxes.data.length ? (
                boxes.data.map(box => <Card to={'/' + organization.data.uri + '/' + box.data.uri} key={box.data.uri} title={box.data.title} description={box.data.content} />)
            ) : (<Loading />)}
            {/* <Button value="Test" type="icon-left" icon="chevron-left"/>
                <Button value="Connexion" type="icon-right" icon="person-circle"/> */}
        </div>
    )
}

export default Home;