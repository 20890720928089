import React, { createContext, useState, useContext, useEffect } from 'react';
import firebase from 'firebase/app'
import { OrganizationContext } from './OrganizationContext';
import { BoxContext } from './BoxContext';

export const IdeaContext = createContext();

const IdeaContextProvider = (props) => {
    const organizations = firebase.firestore().collection('organization');

    const [ideas, setIdeas] = useState({ box: null, data: [] });

    const { organization } = useContext(OrganizationContext);
    const { box } = useContext(BoxContext);

    useEffect(() => {
        if ((!organization.update && !box.update) && (organization.id && box.id) && ideas.update) {
            organizations.doc(organization.id).collection('box').doc(box.id).collection('idea').get().then(snapIdeas => {
                var data = [];
                snapIdeas.forEach(doc => {
                    data.push({
                        id: doc.id,
                        data: doc.data()
                    });
                });
                setIdeas({
                    box: box.id,
                    data
                });
                console.log('IdeaContext', data.length + ' ideas updated')
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization, box])

    const updateIdeas = () => {
        if (ideas.data.length === 0) {
            setIdeas({
                box: null,
                update: true,
                data: []
            });
        }
    }

    // const getIdeas = () => {
    //     if (organization.id && box.id && category.id) {
    //         const unsub = firebase.firestore().collection('organization').doc(organization.id).collection('box').doc(box.id).collection('category').doc(category.id).collection('idea').onSnapshot(snapIdeas => {
    //             var liste = [];
    //             snapIdeas.forEach(doc => {
    //                 liste.push({id:doc.id,data:doc.data()});
    //             });
    //           setIdeas(liste);
    //         }, err => {
    //             console.log(`Encountered error: ${err}`);
    //           });
    //           return () => unsub();
    //     } else {
    //         return null;
    //     }
    // }

    return (
        <IdeaContext.Provider value={{ ideas, updateIdeas }}>
            {props.children}
        </IdeaContext.Provider>
    );
}

export default IdeaContextProvider;