import React from 'react';
import './Button.css'
import Icon from '../../assets/icons';
import { Link } from 'react-router-dom';
import { LoadingContainer } from '../overlay/desktop/Loading';

const Button = ({
    to = "/",
    value = "",
    icon = "",
    type = "text",
    background = true,
    height = "",
    action = false
}) => {
    let content = false;
    switch (type) {
        case "text":
            content = <span>{value}</span>;
            break;
        case "icon-left":
            content = (
                <>
                    <Icon name={icon} fill="#4A4A4A" height={height ? height : "16px"} />
                    <span className="button-separator-thin" />
                    <span className="button-content-text">{value}</span>
                </>
            );
            break;
        case "icon-right":
            content = (
                <>
                    <span>{value}</span>
                    <span className="button-separator-thin" />
                    <Icon name={icon} fill="#4A4A4A" height={height ? height : "20px"} />
                </>
            );
            break;
        case "icon":
            content = <Icon name={value} />;
            break;
        default:
            content = (<div>no button type found</div>);
            break;
    }

    return content ? (action
        ? (<div onClick={() => action()} className={background ? "button-base" : "button-transparent"}>{content}</div>) 
        : (<Link className={background ? "button-base" : "button-transparent"} to={to}>{content}</Link >)
    ) : <LoadingContainer/>
}

export default Button;

// const ButtonIcon = (props) => {
// // const ButtonIcon = (props) => {
//     //const {icon} = props;
//     return (
//         <div className="button icon" >
//             <img src={logoLarge} alt="a"/>
//         </div>
//     );
// }

// const ButtonIconRight = (props) => {
//     const {type, langage, onClick} = props;
//     return (
//         <div className="button icon-left" onClick={() => onClick()}>
//             <span>{translations[langage][type]}</span>
//             <span><img src={peopleIcon} alt="a" /></span>
//         </div>
//     );
// }

// const ButtonProfile = (props) => {
//     const {onClick,initials,logout} = props;
//     return (
//         <div className="dropdown">
//         <div className="button profile" onClick={() => onClick()}>
//             <p>{initials}</p>
//         </div>
//         <div className="dropdown-content">
//         <Link to={'/create'}>Créer une boîte</Link>
//         <a onClick={() => {logout()}} to='/'>Déconnexion</a>
//       </div>
//       </div>
//     );
// }

// const ButtonTextIconLeft = (props) => {
//     const {onClick} = props;
//     return (
//         <div className="button profile" onClick={() => onClick()}>
//             <p>GT</p>
//         </div>
//     );
// }

// export {ButtonIconRight, ButtonProfile, ButtonIcon, ButtonTextIconLeft}