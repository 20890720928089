import React, { useContext, useState, useEffect } from 'react'
import { UserContext } from '../../../contexts/UserContext'
import { Redirect } from 'react-router-dom';
import { NavigationContext } from '../../../contexts/NavigationContext';
import Locales from '../../../assets/translation/locales';
import Button from '../../../components/button/Button';
import TextField from '../../../components/textField/TextField';
import Paper from './../../../components/paper/Paper';
import Divider from '../../../components/divider/Divider';
import Form from '../../../components/form/Form';

const SignIn = () => {
    const { signInUser, user } = useContext(UserContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const { setNavigation } = useContext(NavigationContext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setNavigation('Connexion', 'home'), []);

    if (user.isConnected) {
        return <Redirect to="/" />
    } else {
        return (
            <div>
                <h2>{Locales(user.data.langage, "to-connect")}</h2>
                <Paper padding="medium" align="center">
                    <Form onSubmit={() => signInUser(email, password)}>
                        <TextField label={Locales(user.data.langage, "email")} setValue={setEmail} value={email} type="email" />
                        <Divider />
                        <TextField label={Locales(user.data.langage, "password")} setValue={setPassword} value={password} type="password" />
                        <Divider />
                        {user.status ? <div><Divider /><p className="error-message">{user.status}</p></div> : null}
                        <Button value={Locales(user.data.langage, "connect")} action={() => signInUser(email, password)} />
                    </Form>
                    <Divider color="#D8D8D8" height="2px" margin="20px" />
                    <Button to="/signup" value={Locales(user.data.langage, "no-account-yet")} />
                </Paper>
            </div>
        )
    }
}

export default SignIn
