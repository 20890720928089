import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { NavigationContext } from '../../contexts/NavigationContext';

const BreadCrumbs = () => {
    const { path } = useContext(NavigationContext);
    return path ? (
        <div>
            <Link key="/" to="/">{path[0].name}</Link>
            {path.map(breadcrumb => breadcrumb.uri !== "/" ? (
            <Link key={breadcrumb.uri} to={breadcrumb.uri ? breadcrumb.uri : "/"}> / {breadcrumb.name}</Link>
            ) : null)}
        </div>
    ) : null;
}

export default BreadCrumbs;