import React, { useContext, useEffect } from 'react';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { NavigationContext } from '../../../contexts/NavigationContext';
import Loading, { LoadingContainer } from '../../../components/overlay/desktop/Loading';
import { BoxContext } from '../../../contexts/BoxContext';
import Card from '../../../components/card/Card';

const OrganizationHome = (props) => {

    const { organization, updateOrganization } = useContext(OrganizationContext)
    const { setNavigation } = useContext(NavigationContext);
    const { organizationURI } = props.match.params;
    const { boxes, updateBoxes } = useContext(BoxContext);

    useEffect(() => {
        updateOrganization('uri', organizationURI);
        updateBoxes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        organization.data ? setNavigation(organization.data.name, 'home') : setNavigation('Chargement...', 'home');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization]);

    return organization.data ? (
        <div>
            <h3>Découvrir les boîtes à idées de {organization.data.name}</h3>
            {boxes.data.length ? (
                boxes.data.map(box => <Card to={'/' + organization.data.uri + '/' + box.data.uri} key={box.data.uri} title={box.data.title} description={box.data.content} />)
            ) : (<Loading />)}
        </div>
    ) : (<LoadingContainer />);
}

export default OrganizationHome;