import React, { useContext, useEffect } from 'react';
import { BoxContext } from '../../../contexts/BoxContext';
import { NavigationContext } from '../../../contexts/NavigationContext';
import { LoadingContainer } from '../../../components/overlay/desktop/Loading';
import { Redirect } from 'react-router-dom';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import Card from '../../../components/card/Card';
import locales from '../../../assets/translation/locales';
import Container from '../../../components/container/Container';
import l from '../../../assets/translation/locales';

const BoxHome = (props) => {
    // Contexts imports
    const { setNavigation, setPath } = useContext(NavigationContext);
    const { organization, updateOrganization } = useContext(OrganizationContext)
    const { boxes, updateBoxes } = useContext(BoxContext);
    const { organizationURI, boxURI } = props.match.params;
    const box = (boxes && boxes.organization === organization.id) ? boxes.data.filter(box => box.data.uri === boxURI)[0] : false;

    // Organization, boxes and navigation status updates
    useEffect(() => {
        updateOrganization('uri', organizationURI)
        updateBoxes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (box) {
            setNavigation(box.data.title, 'organization');
            setPath([{ name: l("home"), uri: "/" }, { name: organization.data.name, uri: organizationURI }, { name: box.data.title, uri: boxURI }]);
        } else {
            setNavigation('Chargement...', 'home');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [box]);

    return box && box.data.categories ? (
        <div>
            <p>{box.data.content}</p>
            <Card size="large" to={`/${organizationURI}/${boxURI}/all`} key="all" title={locales("all")} description={locales("all-description")} />
            <h2>{`${locales("there-are")} ${box.data.categories.length} ${locales("categories")}`}</h2>
            <Container columns="2">
                {box.data.categories.length ? (
                    box.data.categories.map(category => <Card size="medium" to={`/${organizationURI}/${boxURI}/${category.uri}`} key={category.uri} title={category.title} description={locales("all-description")} />)
                ) : <></>}
            </Container>
        </div>
    ) : box ? (
        <div>
            <Redirect to={'/' + organizationURI + '/' + boxURI + '/all'} />
        </div>
    ) : (
                <div className="content">
                    <LoadingContainer />
                </div>
            );
}

export default BoxHome;