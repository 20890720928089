import React from 'react';
import './Avatar.css'

const Avatar = (props) => {
    const { 
        type = "letter",
        value = "AA",
        onHover = false,
        onQuitHover = false,
        avatarFront = false 
    } = props
    switch (type) {
        case "letter":
            return (
                <div className={"avatar" + (avatarFront ? " avatar-front" : "")} onMouseEnter={onHover} onMouseLeave={onQuitHover}>
                    <span>{value}</span>
                </div>
            );
        default:
            return (<></>);
    }
}

export default Avatar;