import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Home from './screens/home/desktop/Home'
import SignIn from './screens/auth/desktop/SignIn';
import SignUp from './screens/auth/desktop/SignUp';
import UserContextProvider from './contexts/UserContext';
import NavigationContextProvider from './contexts/NavigationContext';
// eslint-disable-next-line
import firebase from './config/firebaseConfig';
import BoxContextProvider from './contexts/BoxContext';
import Navbar from './components/navbar/desktop/Navbar';
import BoxHome from './screens/box/desktop/BoxHome';
import IdeaContextProvider from './contexts/IdeaContext';
import OrganizationContextProvider from './contexts/OrganizationContext';
import CategoryContextProvider from './contexts/CategoryContext';
import OrganizationHome from './screens/organization/desktop/OrganizationHome';
import IdeaHome from './screens/idea/dekstop/IdeaHome';
import Container from './components/container/Container';
import BoxCreate from './screens/box/desktop/BoxCreate';
import BreadCrumbs from './components/breadcrumbs/Breadcrumbs';

function App() {
    return (
        <UserContextProvider>
            <NavigationContextProvider>
                <OrganizationContextProvider>
                    <BoxContextProvider>
                        <CategoryContextProvider>
                            <IdeaContextProvider>
                                <BrowserRouter>
                                    <div className="App light">
                                        <Navbar />
                                        <Container>
                                            <BreadCrumbs />
                                            <Switch>
                                                <Route exact path='/' component={Home} />
                                                <Route exact path='/create-box' component={BoxCreate} />
                                                <Route path='/signin' component={SignIn} />
                                                <Route path='/signup' component={SignUp} />
                                                <Route exact path='/:organizationURI' component={OrganizationHome} />
                                                <Route exact path='/:organizationURI/:boxURI' component={BoxHome} />
                                                <Route exact path='/:organizationURI/:boxURI/:categoryURI' component={IdeaHome} />
                                            </Switch>
                                        </Container>
                                    </div>
                                </BrowserRouter>
                            </IdeaContextProvider>
                        </CategoryContextProvider>
                    </BoxContextProvider>
                </OrganizationContextProvider>
            </NavigationContextProvider>
        </UserContextProvider>
    );
}

export default App;