import React, { createContext, useState, useEffect, useContext } from 'react';
import firebase from 'firebase/app'
import { UserContext } from './UserContext';

export const OrganizationContext = createContext();

const OrganizationContextProvider = (props) => {
    const organizations = firebase.firestore().collection('organization');

    const [organization, setOrganization] = useState({});
    const { user } = useContext(UserContext);
    // au repos  : {id,name,uri}
    // en action : {...update:{key:'propriété à mettre à jour',value:'valeur à mettre à jour'}}

    useEffect(() => {
        if (organization.update) {
            organizations.where(organization.update.key, '==', organization.update.value).get().then(snapshot => {
                if (snapshot.empty) {
                } else {
                    snapshot.forEach(doc => {
                        setOrganization({
                            id: doc.id,
                            data: doc.data()
                        });
                        console.log('OrganizationContext', 'organization ' + doc.id + ' updated');
                        console.log(user)
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization])

    const updateOrganization = (key, value) => {
        if (!((organization.data) && (organization.data[key]) && (organization.data[key] === value))) {
            setOrganization({
                ...organization,
                update: {
                    key,
                    value
                }
            });
        }
    }

    return (
        <OrganizationContext.Provider value={{ organization, updateOrganization }}>
            {props.children}
        </OrganizationContext.Provider>
    );
}

export default OrganizationContextProvider;