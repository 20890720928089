import React, { createContext, useState, useContext, useEffect } from 'react';
import firebase from 'firebase/app'
import { OrganizationContext } from './OrganizationContext';
import { BoxContext } from './BoxContext';

export const CategoryContext = createContext();

const CategoryContextProvider = (props) => {
    const organizations = firebase.firestore().collection('organization');

    const [category, setCategory] = useState({});
    const [categories, setCategories] = useState({ box: null, data: [] });

    const { organization } = useContext(OrganizationContext);
    const { box } = useContext(BoxContext);

    useEffect(() => {
        if (category.update && !box.update && !organization.update) {
            organizations.doc(organization.id).collection('box').doc(box.id).collection('category').where(category.update.key, '==', category.update.value).get().then(snapshot => {
                console.log(category.update.key);
                if (snapshot.empty) {
                    console.log('No matching documents.');
                    return;
                }
                snapshot.forEach(doc => {
                    setCategory({
                        id: doc.id,
                        data: doc.data()
                    });
                    console.log('CategoryContext', 'category ' + doc.id + ' updated');
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [box, category])

    const updateCategory = (key, value) => {
        if (!((category.data) && (category.data[key]) && (category.data[key] === value))) {
            setCategory({
                ...category,
                update: {
                    key,
                    value
                }
            });
        }
    }

    useEffect(() => {
        if ((!organization.update && !box.update) && categories.update) {
            organizations.doc(organization.id).collection('box').doc(box.id).collection('category').get().then(snapshot => {
                if (snapshot.empty) {
                    console.log('pas de catégories')
                }
                var liste = [];
                snapshot.forEach(doc => {
                    liste.push({
                        id: doc.id,
                        data: doc.data()
                    });
                });
                setCategories({
                    box: box.id,
                    data: liste
                });
                console.log('CategoryContext', liste.length + ' categories updated')
            }, err => {
                console.log(`Encountered error: ${err}`);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories, box, categories]);

    const updateCategories = () => {
        if (categories.box !== box.id) {
            setCategories({
                box: null,
                update: true,
                data: []
            });
        }
    }

    const getCategory = (categoryURI) => {
        if (organization.id && box.id) {
            firebase.firestore().collection('organization').doc(organization.id).collection('box').doc(box.id).collection('category').where('uri', '==', categoryURI).get().then(snapCat => {
                snapCat.forEach(cat => {
                    setCategory({
                        id: cat.id,
                        title: cat.data().title
                    })
                });
            }, err => {
                console.log(`Encountered error: ${err}`);
            });
        } else {
            return null;
        }
    }

    return (
        <CategoryContext.Provider value={{ category, categories, getCategory, updateCategory, updateCategories }}>
            {props.children}
        </CategoryContext.Provider>
    );
}

export default CategoryContextProvider;