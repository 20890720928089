import React from 'react';
import './Card.css'
import { Link } from 'react-router-dom';
import Paper from '../paper/Paper';

const Card = (props) => {
    const {
        size = "large",
        to = false,
        title = "",
        description = ""
    } = props;
    const content = (
        <Paper size={size}>
            <p className="card-title">{title}</p>
            <p className="card-description">{description}</p>
        </Paper>
    );
    return to ? (
        <Link to={to}>
            {content}
        </Link>
    ) : (<>{content}</>);
}

export default Card;